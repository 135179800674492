<template>
  <div ref="screenshot">
    <div class="ml-3">
      <v-row>
        <v-col :cols="responsive == 'PC' ? 9 : 6" class="pa-0">
          <h4 class="ml-2 lightred--text">{{ symbol != null ? symbol.replace(/USD/, '') : '' }} ioBots Signal</h4>
        </v-col>
        <v-col :cols="responsive == 'PC' ? 2 : 3" class="pa-0">
          <h4 class="float-right" :class="[performance == '--' ? '' : (performance > 0 ? 'green--text' : 'red--text'),responsive == 'PC' ? '' : 'mr-5']">{{ performance == '--' ? '' : (performance > 0 ? ('+' + formatNum(performance.toFixed(2)) + '%') : (formatNum(performance.toFixed(2)) + '%')) }}</h4>
        </v-col>
        <v-col :cols="responsive == 'PC' ? 1 : 3" class="pa-0 pr-3" :class="[responsive == 'PC' ? '' : 'ml-n6']">
          <span v-show="!isTakingScreenshot" class="float-right" :class="[responsive == 'PC' ? 'mr-3' : 'mr-n3']">
            <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getSignalHistoryChart">mdi-refresh</v-icon>
            <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
            <v-icon size="18" class="lineblue--text mr-1" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="responsive == 'PC' ? 9 : 6" class="pa-0">
          <h4 class="ml-2 lineblue--text">{{ symbol != null ? (symbol.replace(/USD/, '') + ' buy & hold') : '' }}</h4>
        </v-col>
        <v-col :cols="responsive == 'PC' ? 2 : 3" class="pa-0">
          <h4 class="float-right" :class="[buyAndHoldPerformance == '--' ? '' : (buyAndHoldPerformance > 0 ? 'green--text' : 'red--text'),responsive == 'PC' ? '' : 'mr-5']">{{ buyAndHoldPerformance == '--' ? '' : (buyAndHoldPerformance > 0 ? ('+' + formatNum(buyAndHoldPerformance.toFixed(2)) + '%') : (formatNum(buyAndHoldPerformance.toFixed(2)) + '%')) }}</h4>
        </v-col>
        <v-col :cols="responsive == 'PC' ? 1 : 3" class="pa-0">
          
        </v-col>
      </v-row>
    </div>
    <div :id="symbol" class="mr-3" style="height: 420px;"></div>
    <v-dialog v-model="screenshotDialog" persistent width="450">
      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="black--text">{{$t("common.imageURL")}}</span>
          <v-spacer></v-spacer>
          <v-btn light icon @click="screenshotDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="!uploadBase64Loading" class="mt-3">
            <v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled hide-details class="mt-3 mb-3"></v-text-field>
            <v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">{{$t("common.save")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">{{$t("common.open")}}</v-btn>
          </div>
          <div v-show="uploadBase64Loading" class="mt-3 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
  svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  import html2canvas from 'html2canvas';
  export default {
    data() {
      return {
        // 图片弹窗
        screenshotDialog: false,
        // candleChart图片base64地址
        imageBase64Url: null,
        // candleChart图片http地址
        imageHttpUrl: null,
        // 上传图片加载中
        uploadBase64Loading: false,
        // 是否正在截图
        isTakingScreenshot: false,
        // 图表id
        // id: 'renkoChart',
        // 生成的整个图表对象
        chart: null,
        // 图表加载中
        chartLoading: false,
        // 收益
        performance: '--',
        // 持有收益
        buyAndHoldPerformance: '--',
        // 数据选项
        option: {
          // 图表类型
          chart: {
              backgroundColor: this.darkMode ? 'dark' : '',
              type: 'line',
              // x方向和y方向都可以用鼠标拖拽放大
              zoomType: 'xy'
          },
          title: {
              text: '',
              align: 'left',
              style: {
                  color: '#9E9E9E'
              },
          },
          subtitle: {
              text: '',
              align: 'left',
              style: {
                  color: '#999999'
              },
          },
          // x轴
          xAxis: {
            categories: [],
            lineWidth: 1,
            tickLength: 8,
            tickWidth: 1,
            tickInterval: 10,
            tickPosition: 'inside',
            type: 'datetime',
            dateTimeLabelFormats: {
                millisecond: '%m/%d/%Y %H:%M:%S',
                second: '%m/%d/%Y %H:%M:%S',
                minute: '%m/%d/%Y %H:%M',
                hour: '%m/%d/%Y %H:%M',
                day: '%m/%d',
                week: '%m/%d',
                month: '%m/%Y',
                year: '%Y'
            },
            labels: {
                enabled: true,
                backgroundColor: '#ff6f61',
                format: '{value:%m/%d}'
            }
        },
        // y轴
        yAxis: {
          allowDecimals: true,
          title: {
              text: null
          },
          // max: 2.2,
          // min: -2.2,
          // tickInterval: 0.2,
          // startOnTick: true,
          visible: true,
          // 横线宽度设置为0表示不显示横线
          gridLineWidth: 0,
          opposite: true,
          offset: 60,
          labels: {
              align: "right",
              x: 5,
              formatter: function(){
                if (this.value == null) return '';
                if (this.value == NaN || this.value == 'NaN') return '';
                if (this.value == 'undefined') return '';
                if (this.value == '--') return '--';
                let number = this.value.toFixed(2) + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return '$' + integerPart + decimalPart;
                // return '$' + this.value.toFixed(2);
              }
          },
          // plotLines: [{
          //     value: 0,
          //     color: '#9e9e9e',
          //     dashStyle: 'shortdash',
          //     width: 1
          //   }]
        },
        // 鼠标悬浮提示框
        tooltip: {
            valueDecimals: 2,
            valuePrefix: '$',
            valueSuffix: '',
            followTouchMove: true,
            dateTimeLabelFormats: {
                millisecond: '%m/%d/%Y %H:%M:%S UTC',
                second: '%m/%d/%Y %H:%M:%S UTC',
                minute: '%m/%d/%Y %H:%M UTC',
                hour: '%m/%d/%Y %H:%M UTC',
                day: '%m/%d/%Y %H:%M UTC',
                week: '%m/%d %H:%M UTC',
                month: '%m/%Y',
                year: '%Y'
            }
        },
        // 数据
        series: [{
            name: 'ioBots Signal',
            data: [],
            color: '#FEE108',
            lineWidth: 3
          },
          {
              name: 'buy & hold',
              data: [],
              color: '#2196f3',
              lineWidth: 3
          }
        ],
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        },
        // 图例导航
        legend: {
            enabled: false,
            align: 'left',
            itemStyle: {
                color: '#999999'
            },
            itemHoverStyle: {
                color: '#999999'
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0',
                    fontFamily: '\'Noto Sans SC\', sans-serif'
                }
            }
        },
        navigation: {
            buttonOptions: {
                symbolStroke: 'black',
                theme: {
                    fill: '#EEEEEE',
                },
            }
        },
        // 图形参数
        plotOptions: {
            line: {
                lineWidth: 2,
                cursor: 'pointer',
                marker: {
                    enabled: false
                }
            }
        },
        // 版权信息
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        loading: {
            style: {
                backgroundColor: 'silver'
            },
            labelStyle: {
                color: 'white',
                fontSize: '36px'
            }
          }
        },
      }
    },
    props: ['symbol' ],
    watch: {
      // 监听加载中
      chartLoading(newVal, oldVal){
        if (this.chart) {
          if (newVal) {
            this.chart.showLoading();
          } else {
            this.chart.hideLoading();
          }
        }
      },
      // 监听传来的货币
      symbol(newVal, oldVal){
        if (newVal) {
          this.getSignalHistoryChart();
        }
      },
    },
    created(){

    },
    mounted(){
      Highcharts.setOptions({
        global: {
          // 是否使用UTC时间
          useUTC: this.time == 'UTCTime'
        },
        lang: {
          noData: '',
          contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
          printChart: this.$t('highcharts.printChart'),
          resetZoom: this.$t('highcharts.resetZoom'),
          resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
          downloadPNG: this.$t('highcharts.downloadPNG'),
          downloadJPEG: this.$t('highcharts.downloadJPEG'),
          downloadPDF: this.$t('highcharts.downloadPDF'),
          downloadSVG: this.$t('highcharts.downloadSVG')
        }
      });
      this.renderChart();
      this.getSignalHistoryChart();
    },
    computed: {
      ...mapGetters(['responsive', 'time', 'darkMode']),
    },
    methods:{
      //全屏
      toggleFullscreen() {
        if (this.chart.fullscreen) {
          this.chart.fullscreen.toggle();
        }
      },
      // 渲染图表
      renderChart() {
          this.chart = Highcharts.chart(this.symbol, this.option);
      },
      // 无数据
      noData() {
        Highcharts.setOptions({
            lang: {
                noData: this.$t('highcharts.noData'),
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.renderChart();
      },
      // 查询数据
      getSignalHistoryChart(){
        this.chartLoading = true;
        this.option.series[0].data = [];
        let api = config.baseUrl + "/signal/public/chart/history/" + this.symbol;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200) {
              let dataList = data.result.data;
              let valueList = data.result.value;
              this.option.series[0].data = dataList;
              this.option.series[1].data = valueList;
              let first = dataList[0];
              let firstValue = valueList[0];
              for(let i = 0, len = dataList.length; i < len; i++){
                if (dataList[i] != 0) {
                  first = dataList[i];
                  break;
                }
              }
              for(let i = 0, len = valueList.length; i < len; i++){
                if (valueList[i] != 0) {
                  firstValue = valueList[i];
                  break;
                }
              }
              let last = dataList[dataList.length - 1];
              let lastValue = valueList[valueList.length - 1];
              this.performance = ((last - first) / first) * 100;
              this.buyAndHoldPerformance = ((lastValue - firstValue) / firstValue) * 100;
              // for(let i = 0, len = data.result.date.length; i < len; i++){
              //   // console.log(data.result.date[i])
              //   data.result.date[i] = data.result.date[i].substring(5);
              // }
              this.option.xAxis.categories = data.result.date;
              this.chartLoading = false;
              // 重新渲染图表
              this.renderChart();
          } else {
              this.option.series[0].data = [];
              this.option.series[1].data = [];
              this.option.xAxis.categories = [];
              this.chartLoading = false;
              this.noData();
          }
        })
      },
      // 生成图片
      generateImage() {
        let pageYoffset = window.pageYoffset;
        let documentElementScrollTop = document.documentElement.scrollTop;
        let scrollTop = document.body.scrollTop;
        window.pageYoffset = 0;
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        // 正在截图
        this.isTakingScreenshot = true;
        let _this = this;
        // 延时操作，不要问为什么，不延迟不可以
        setTimeout(function() {
          // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
          html2canvas(_this.$refs.screenshot, {
            backgroundColor: _this.darkMode ? '#303030' : '#EEEEEE',
            useCORS: true
          }).then((canvas) => {
            let url = canvas.toDataURL('image/png');
            _this.imageBase64Url = url;
            // 生成截图完毕
            _this.isTakingScreenshot = false;
            window.pageYoffset = pageYoffset;
            document.documentElement.scrollTop = documentElementScrollTop;
            document.body.scrollTop = scrollTop;
            _this.uploadBase64Loading = true;
            _this.screenshotDialog = true;
            // 把生成的base64位图片上传到服务器,生成在线图片地址
            _this.uploadBase64();
          })
        }, 1);
      },
      // 上传base64
      uploadBase64(){
        this.uploadBase64Loading = true;
        let api = config.baseUrl + '/upload/public/base64';
        let param = { base64: this.imageBase64Url };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          this.uploadBase64Loading = false;
          if (data.code == 200) {
            this.imageHttpUrl = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.uploadBase64Loading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 点击下载图片
      saveImage(){
        let a = document.createElement('a');
        // 设置图片地址
        a.href = this.imageBase64Url;
        // 设定下载名称
        a.download = this.symbol.replace(/USD/, '') + '-Trading-Signal-Result';
        // 点击触发下载
        a.click();
      },
      // 复制成功回调函数
      copySuccess(){
        this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
      },
      // 复制失败回调函数
      copyError(){
        this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
      },
      // 格式化数字
      formatNum(num) {
        return formatNumber(num);
      },
    }
  }
</script>