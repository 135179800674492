<template>
  <div>
    <v-container fluid class="flex-m grid-list-xl mt-3" v-if="responsive == 'PC'">
      <v-navigation-drawer fixed :width="370" class="z-index flex-m pb-0 mb-0">
          <template>
            <v-row class="mt-7 mr-0">
              <v-col cols="2" class="pr-1 mr-2">
                <v-card tile flat class="pointer" :class="[darkMode?'black':'greybg']">
                  <div class="fs-10">
                    <!-- <v-card tile hover flat v-for="(s, i) in signalSymbols" :key="i" class="symbol del-underline"
                      :class="[s == symbol ? (darkMode ?'grey darken-4': 'white fs-22') : (darkMode?'darkbg':'greybg')]"
                      :to="'/signal/history/' + s">
                      <app-icon :symbol="s" class="pa-1"></app-icon>
                    </v-card> -->
                    <v-card hover tile flat v-for="(s, i) in signalSymbols" :key="i" class="signal-history-symbol del-underline" :class="[s == symbol ? (darkMode ?'grey darken-4': 'white fs-10') : (darkMode?'darkbg':'greybg')]" :to="'/signal/history/' + s">
                      {{ getBaseCurrency(s) }}
                    </v-card>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="9" class="px-0 mx-0">
                <v-row no-gutters>
                  <v-col cols="1" class="mt-3 pt-0">
                    <v-icon color="grey" small @click="gotoMoreSignals">mdi-arrow-left</v-icon>
                  </v-col>
                  <v-col cols="9" offset-md="1">
                    <v-row>
                      <v-col cols="2" md="6">
                        <span class="fs-18">{{ symbol }}</span>
                      </v-col>
                      <v-col cols="4" md="6" class="text-right fs-20">
                        <span v-if="symbol == 'BSVUSD' || symbol == 'TRXUSD' || symbol == 'NEOUSD' || symbol == 'LINKUSD' || symbol == 'DASHUSD'">
                          <symbol-price ref="currentPrice" exchangeCode="okex" tradingCategory="spot" :symbol="symbol" type="fill"
                            :decimalNumber="signalDecimalNumbers[symbol]"></symbol-price>
                        </span>
                        <span v-else>
                          <span v-if="symbol != null && symbol.indexOf('USD') > -1">
                            <symbol-price ref="currentPrice" exchangeCode="kraken" tradingCategory="spot" :symbol="symbol"
                            type="fill" :decimalNumber="signalDecimalNumbers[symbol]"></symbol-price>
                          </span>
                          <span v-else>
                            <span :class="[financeSignalPrice.symbol != null ? (financeSignalPrice.price < financeSignalPrice.previousPrice ? 'red--text' : 'green--text') : 'green--text'  ]">
                              {{ financeSignalPrice.symbol != null ? financeSignalPrice.price.toFixed(signalDecimalNumbers[symbol]) : '--' }}
                            </span>
                          </span>
                        </span>
                        <div class="grey--text text--lighten-1 fs-12">{{$t("signalHistory.currentPrice")}}</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
      </v-navigation-drawer>
    </v-container>
    <v-container fluid class="flex-m grid-list-xl px-0 pb-0 pt-0 pa-0" v-if="responsive == 'PC'">
      <v-row class="ml-420">
        <v-col cols="12" class="pl-0">
          <!-- <span class="font-24 fw-bold lightred--text pl-2">{{$t("signalHistory.title")}}</span> -->
          <signal-history-chart :symbol="symbol"></signal-history-chart>
           <v-row dense v-if="signalLoading"> 
              <v-col cols="12">
                <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
            </v-col>
           </v-row>
          <v-data-table  v-if="!signalLoading" :headers="signalHistoryHeader" :items="signalHistories" hide-default-footer class="mt-4 pr-1"
            :no-data-text="signalLoading ? '':$t('signalHistory.noDataAvailable')" :items-per-page="pageSize" >
            <template v-slot:item="props">
              <tr>
                <td>{{ formatDateFunction(props.item.timestamp) }}</td>
                <td :class="[props.item.side == 'long' ? 'green--text' : 'red--text']">{{ formatFirstLetterUpperCase(props.item.side) }}</td>
                <td><span v-signal-interval="props.item.timeInterval"></span></td>
                <td class="text-right">{{ (props.item.entryPrice.toFixed(signalDecimalNumbers[symbol])) | NumFormat }}</td>
                <td class="text-right">{{ (props.item.exitPrice > 0 ? props.item.exitPrice.toFixed(signalDecimalNumbers[symbol]) : '--') | NumFormat }}</td>
                <td class="text-right" :class="props.item.profitAndLossColor">{{ props.item.profitAndLoss }}</td>
                <td class="text-right">{{ formatFirstLetterUpperCase(props.item.status) }}</td>
              </tr>
            </template>
          </v-data-table>
           <div class="mt-4 pl-0 float-left" v-if="signalHistories.length > 0">
          <v-pagination v-model="pageNo" dark color="lightred" circle :total-visible="10" :length="totalPage" @input="getSignalHistoriesBySymbol"
              prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :items-per-page="pageSize"></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- mobile 手机 -->
    <v-container fluid class="flex-b grid-list-xl mt-12 pb-0 px-0 pt-0" v-if="responsive == 'mobile'">
      <signal-mobile-change></signal-mobile-change>
      <v-row>
        <v-col cols="8" sm="4" md="4">
          <v-card flat>
            <v-row>
              <v-col cols="6">
                <span class="ml-2">
                  <app-icon :symbol="symbol"></app-icon>
                </span>
                <span class="ml-2">{{ symbol }}</span>
              </v-col>
              <v-col cols="6" class="text-right pr-6">
                <span v-if="symbol == 'BSVUSD' || symbol == 'TRXUSD' || symbol == 'NEOUSD' || symbol == 'LINKUSD' || symbol == 'DASHUSD'">
                  <symbol-price ref="currentPrice" exchangeCode='okex' tradingCategory='spot' :symbol='symbol' type='fill'
                    :decimalNumber="signalDecimalNumbers[symbol]"></symbol-price>
                </span>
                <span v-else>
                  <span v-if="symbol != null && symbol.indexOf('USD') > -1">
                    <symbol-price ref="currentPrice" exchangeCode='kraken' tradingCategory='spot' :symbol='symbol' type='fill'
                    :decimalNumber="signalDecimalNumbers[symbol]"></symbol-price>
                  </span>
                  <span v-else>
                    <span :class="[financeSignalPrice.symbol != null ? (financeSignalPrice.price < financeSignalPrice.previousPrice ? 'red--text' : 'green--text') : 'green--text'  ]">
                      {{ financeSignalPrice.symbol != null ? financeSignalPrice.price.toFixed(signalDecimalNumbers[symbol]) : '--' }}
                    </span>
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="2">
            <v-menu :nudge-bottom="40" :nudge-width="80" :max-height="350" v-if="signalSymbols != null">
              <template v-slot:activator="{ on }">
                <v-btn color="lightred" fab light x-small v-on="on" class="mt-1">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(s, i) in signalSymbols" :key="i">
                  <router-link class="del-underline" :to="'/signal/history/' + s">
                    <v-card flat tile class="del-underline">
                      {{ s }}
                    </v-card>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-col>
        <v-col cols="12" class="pb-16">
          <v-row class="mt-3 mx-1">
            <v-col cols="12" class="pb-0">
              <signal-history-chart :symbol="symbol"></signal-history-chart>
            </v-col>
          </v-row>
          <v-card flat v-for="(signalHistories, i) in signalHistories" :key="i" class="mt-2 my-3 mx-3">
            <v-row class="mt-3 mx-1">
              <v-col cols="12" class="pb-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signalHistory.date")}}
                </span>
                <span class="float-right">
                  {{ formatDateFunction(signalHistories.timestamp) }}
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pb-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signals.interval")}}
                </span>
                <span class="float-right">
                  <span v-signal-interval="signalHistories.timeInterval"></span>
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pb-0 py-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signalHistory.side")}}
                </span>
                <span class="float-right" :class="[signalHistories.side == 'long' ? 'green--text' : 'red--text']">
                  {{ formatFirstLetterUpperCase(signalHistories.side) }}
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pb-0 py-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signalHistory.entryPrice")}}
                </span>
                <span class="float-right">
                  {{ (signalHistories.entryPrice.toFixed(signalDecimalNumbers[symbol])) | NumFormat }}
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pb-0 py-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signalHistory.exitPrice")}}
                </span>
                <span class="float-right">
                  {{ (signalHistories.exitPrice > 0 ? signalHistories.exitPrice.toFixed(signalDecimalNumbers[symbol]) : '--') | NumFormat }}
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pb-0 py-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signalHistory.result")}}
                </span>
                <span class="float-right" :class="signalHistories.profitAndLossColor">
                  {{ signalHistories.profitAndLoss }}
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pb-0 py-0">
                <span class="grey--text text--lighten-1">
                  {{$t("signalHistory.status")}}
                </span>
                <span class="float-right">
                  {{ formatFirstLetterUpperCase(signalHistories.status) }}
                </span>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card>
          <div class="text-center mt-3 mb-5" v-if="signalHistories.length == 0">
            {{ $t('signalHistory.noDataAvailable') }}
          </div>
          <div class="text-center mt-3 mb-5" v-if="signalHistories.length > 0">
            <v-pagination v-model="pageNo" color="lightred" circle :total-visible="10" :length="totalPage" @input="getSignalHistoriesBySymbol"
              prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row class="px-0 mt-10">
        <v-col cols="12" sm="12" md="12" xs="12" class="pb-0 px-0 fixed">
          <v-card flat>
            <div class="d-flex py-3 px-4 fs-20 justify-center">
              <div v-for="(s, i) in signalSymbols" :key="i" class="px-2">
                <router-link class="del-underline pointer" :to="'/signal/history/'  + s" :class="[s == symbol ? 'icon-red' : 'icon-grey']">
                  <app-icon :symbol="s"></app-icon>
                </router-link>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
    <!-- <mobile-btn></mobile-btn>  -->
  </div>
</template>

<script>
  import Vue from "vue";
  import config from "@/config.js";
  import AppIcon from "@/components/Icon.vue";
  import SymbolPrice from "@/pages/exchange/WebSocket.vue";
  import MobileBtn from "@/components/common/MobileBtn";
  import SignalHistoryChart from "@/highcharts/SignalHistoryChart";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        symbol: this.$route.params.symbol,
        drawer: null, // sidebar drawer default true
        pageNo: 1,
        pageSize: 15,
        totalPage: 0,
        signalHistories: [],
        signalHistoryHeader: [{ sortable: false, text: this.$t("signalHistory.date") },
          { sortable: false, text: this.$t("signalHistory.side") },
          { sortable: false, text: this.$t("signals.interval") },
          { sortable: false, align: 'right', text: this.$t("signalHistory.entryPrice") },
          { sortable: false, align: 'right', text: this.$t("signalHistory.exitPrice") },
          { sortable: false, align: 'right', text: this.$t("signalHistory.result") },
          { sortable: false, align: 'right', text: this.$t("signalHistory.status") },
        ],
        signalLoading: false,
        financeSignalPrice: {}
      };
    },
    computed: {
    ...mapGetters(["signalSymbols", "signalDecimalNumbers", "darkMode",'responsive'])
  },
  components: { SymbolPrice, AppIcon, MobileBtn, SignalHistoryChart },
  created() {
    this.$store.dispatch("signalHeaderTitleHandler", 'signalsHistory');
    this.$store.dispatch("signalPageHandler", this.$t("menu.signalsHistory"));
    this.$store.dispatch("mobileBtnPageHandler",1);
  },
  mounted() {
    this.isExistPage();
    this.getFinanceSignalPrice();
    this.getSignalHistoriesBySymbol(this.pageNo);
  },
  watch: {
    $route() {
      this.symbol = this.$route.params.symbol;
    },
    symbol(newValue, oldValue) {
      this.pageNo = 1;
      this.getFinanceSignalPrice();
      this.getSignalHistoriesBySymbol(this.pageNo);
    }
  },
  methods: {
    // 判断是页面否存在
    isExistPage() {
      let existSymbol = isExistSymbol(this.signalSymbols, this.symbol);
      if (!existSymbol) {
        this.$router.push({ name: "page404" });
      }
    },
    // 根据符号分页查询该符号的历史信号集合
    getSignalHistoriesBySymbol(pageNo) {
      this.signalLoading = true;
      let api = config.baseUrl + "/signal/public/getSignalHistoriesBySymbol?symbol=" + this.symbol + "&pageNo=" + (pageNo - 1) + "&pageSize=" + this.pageSize;
      this.$http.get(api).then(
        response => {
          let data = response.data;
          if (data.code == 200) {
            let result = data.result.signalHistories;
            for (let i = 0, len = result.length; i < len; i++) {
              if (result[i].exitPrice == 0) {
                result[i].exitPrice = "--";
                result[i].profitAndLoss = "--";
                continue;
              }
              let profit = "--";
              if ("long" == result[i].side) {
                profit =
                  (result[i].exitPrice - result[i].entryPrice) /
                  result[i].entryPrice;
              } else if ("short" == result[i].side) {
                profit =
                  (result[i].entryPrice - result[i].exitPrice) /
                  result[i].entryPrice;
              }
              let percentage = Number(profit * 100).toFixed(2);
              result[i].profitAndLossColor =
                percentage >= 0 ? "green--text" : "red--text";
              let percentageChange =
                (percentage > 0 ? "+" : "") + percentage + "%";
              result[i].profitAndLoss = percentageChange;
            }
            this.signalHistories = result;
            this.signalLoading = false;
            // this.pageNo += 1;
            this.totalPage = Math.ceil(
              data.result.totalElements / this.pageSize
            );
          } else {
            this.signalHistories = [];
            this.signalLoading = false;
          }
        },
        error => {
          this.$store.dispatch("snackbarMessageHandler", this.$t('common.oops'));
        }
      );
    },
    // 跳转到信号页面
    gotoMoreSignals() {
      this.$router.push({ name: "moreSignals" });
    },
    // 跳转到信号历史页面
    gotoSignalHistory(symbol) {
      this.$router.push({ name: "signalHistory", params: { symbol: symbol } });
    },
    // 首字母大写
    formatFirstLetterUpperCase(message) {
      return toFirstLetterUpperCase(message);
    },
    // 格式化日期
    formatDateFunction(timestamp) {
      if ("localTime" == localStorage.getItem("iobots-time")) {
        return formatDate(timestamp);
      } else {
        return formatUTCDate(timestamp);
      }
    },
    // 计算P/L收益损失率
    calculateProfitAndLoss(index, side, priceA, priceB) {
      if (priceA == 0 || priceB == 0) {
        return "";
      }
      let profit = 0;
      if ("long" == side) {
        profit = (priceB - priceA) / priceA;
      } else if ("short" == side) {
        profit = (priceA - priceB) / priceA;
      }
      let percentage = Number(profit * 100).toFixed(2);
      this.signalHistories[index].profitAndLossColor =
        percentage >= 0 ? "green--text" : "red--text";
      let percentageChange = (percentage > 0 ? "+" : "") + percentage + "%";
      this.signalHistories[index].profitAndLoss = percentage;
      return percentageChange;
    },
    // 获取基础货币
    getBaseCurrency(symbol){
      if (symbol) {
        return symbol.replace(/USD/, '');
      }
      return symbol;
    },
    // 获取金融信号数据
    getFinanceSignalPrice(){
      if (this.symbol == null) {
        this.financeSignalPrice = {};
        return;
      }
      if (this.symbol != null && this.symbol.indexOf('USD') > -1) {
        this.financeSignalPrice = {};
        return;
      }
      this.financeSignalPrice = {};
      let api = config.baseUrl + '/finance/public/signal/price?symbol=' + this.symbol;
      this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
              this.financeSignalPrice = data.result;
          } else {
              this.financeSignalPrice = {};
          }
      });
    }
  }
};
</script>